<template lang="html">
  <div class="">
    <div :style="{height:'70px', width:'100%', display:'flex' }">
      <div :style="{fontSize:'29px', height:'10px',textAlign:'center',fontWeight:'700',marginTop:'2%', marginLeft:'47%',color:'#0A6338'}">
      Analytics {{this.year}}
      </div>
    </div>
    <div :style="{height:'100px',width:'200px',textAlign:'left', marginLeft:'2%' }">
      <div @click="open_google()" :style="{fontSize:'19px', textDecoration:'underline',color:'#0A6338'}">
      To Google analytics
      </div>
      <div @click="open_hotjar()" :style="{fontSize:'19px',textDecoration:'underline', color:'#0A6338'}">
      To hotjar
      </div>
      <div @click="open_leads()" :style="{fontSize:'19px',textDecoration:'underline', color:'#0A6338'}">
      To Alla leads
      </div>
    </div>
    <div :style="{height:'30px', width:'100%', display:'flex' }">
      <div :style="{fontSize:'22px', height:'10px',position:'absolute',fontWeight:'600',marginTop:'0%', marginLeft:'2%',color:'#0A6338'}">
      Users / Companies
      </div>
    </div>
    <div :style="{ height:'300px', width:'100%'}">
      <chart :style="{height:'300px',marginTop:'5%', marginLeft:'4%', width:'96%'}" :option="chartNewComp"></chart>
    </div>
    <div :style="{ height:'300px', width:'100%'}">
      <chart :style="{height:'300px',marginTop:'5%', marginLeft:'4%', width:'96%'}" :option="chartTotalLoggedInComp"></chart>
    </div>
    <div :style="{ height:'300px', width:'100%'}">
      <chart :style="{height:'300px',marginTop:'5%', marginLeft:'4%', width:'96%'}" :option="chartTopLoggedInComp"></chart>
    </div>

    <div :style="{ height:'300px', width:'100%'}">
      <chart :style="{height:'300px',marginTop:'5%', marginLeft:'4%', width:'96%'}" :option="chartwebsitevisits"></chart>
    </div>
    <div :style="{ height:'300px', width:'100%'}">
      <chart :style="{height:'300px',marginTop:'5%', marginLeft:'4%', width:'96%'}" :option="chartTopCompClick"></chart>
    </div>
    <div :style="{height:'30px', width:'100%', display:'flex' }">
        <div :style="{fontSize:'22px', height:'10px',position:'absolute',fontWeight:'600',marginTop:'0%', marginLeft:'2%',color:'#0A6338'}">
          Clicks Categories and Län
        </div>
    </div>
    <div :style="{ height:'300px', width:'100%'}">
      <chart :style="{height:'300px',marginTop:'5%', marginLeft:'4%', width:'96%'}" :option="chartCategoryClicksPerCat"></chart>
    </div>

    <div :style="{ height:'300px', width:'100%'}">
      <chart :style="{height:'300px',marginTop:'5%', marginLeft:'4%', width:'96%'}" :option="chartClicksPerLan"></chart>
    </div>

    <div :style="{height:'30px', width:'100%', display:'flex' }">
        <div :style="{fontSize:'22px', height:'10px',position:'absolute',fontWeight:'600',marginTop:'0%', marginLeft:'2%',color:'#0A6338'}">
          Clicks Categories per week
        </div>
    </div>
    <div :style="{ height:'300px', width:'100%'}">
      <chart
          :style="{height:'300px',marginTop:'5%', marginLeft:'4%', width:'96%'}"
          :option="chartCategoryClicksPerWeek"></chart>
    </div>

    <div :style="{height:'30px', width:'100%', display:'flex' }">
        <div :style="{fontSize:'22px', height:'10px',position:'absolute',fontWeight:'600',marginTop:'0%', marginLeft:'2%',color:'#0A6338'}">
          Clicks län per week
        </div>
    </div>
    <div :style="{ height:'300px', width:'100%'}">
      <chart :style="{height:'300px',marginTop:'5%', marginLeft:'4%', width:'96%'}"
              :option="chartLanClicksPerWeek"></chart>
    </div>

    <div :style="{height:'30px', width:'100%', display:'flex' }">
        <div :style="{fontSize:'22px', height:'10px',position:'absolute',fontWeight:'600',marginTop:'0%', marginLeft:'2%',color:'#0A6338'}">
          Clicks Categories per month
        </div>
    </div>
    <div :style="{ height:'300px', width:'100%'}">
      <chart
          :style="{height:'300px',marginTop:'5%', marginLeft:'4%', width:'96%'}"
          :option="chartCategoryClicksPerMonth"></chart>
    </div>
    <div :style="{height:'30px', width:'100%', display:'flex' }">
        <div :style="{fontSize:'22px', height:'10px',position:'absolute',fontWeight:'600',marginTop:'0%', marginLeft:'2%',color:'#0A6338'}">
          Clicks län per month
        </div>
    </div>
    <div :style="{ height:'300px', width:'100%'}">
      <chart :style="{height:'300px',marginTop:'5%', marginLeft:'4%', width:'96%'}"
              :option="chartLanClicksPerMonth"></chart>
    </div>


    <div :style="{ height:'30px',marginTop:'5%',fontWeight:'600', width:'100%'}">
      Välj  lan
    </div>

    <div class="search_class">
      <button type="button"
        :style="{borderStyle:'none', fontSize:'12px',borderRadius:'22px 22px 22px 22px', backgroundColor:'#0A6338',
         color:'white', width:'100%'}"  v-if="!lan_chosen" @click="open_lan()" name="button">
         Vilket län bor du i?
         <b-icon  icon="caret-down" ></b-icon>
       </button>
      <button type="button"
         :style="{borderStyle:'none', fontColor:'#8A8A8A',borderRadius:'22px 22px 22px 22px', backgroundColor:'#0A6338',color:'white', width:'100%' }"
          v-if="lan_chosen" name="button"
          @click="open_lan()">{{this.län_name}}
        <b-icon  icon="caret-down" ></b-icon>
      </button>
    </div>
    <div v-if= "this.län_opened" :style="{height:'200px',border:0, position:'absolute',
          borderWidth:'0', boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',
          borderRadius:'0px 6px 6px 0px',marginTop:'0%',overflowY:'auto',borderRadius:'8px',
          borderColor:'black',
          opacity:'100%',zIndex:13, width:'55%',marginTop:'0.5%',
           backgroundColor:'white',marginLeft:'0%'}">

    <div  v-for="(län,idx) in län_list" :style="{marginLeft:'4%'}"
          :key="idx" @click="choose_län_func(län.id,län.lan)" >
      <div :style="{height:'27px',fontSize:'17px', display:'flex',marginTop:'1%'}" >
        <div :style="{marginLeft:'1%',marginTop:'0.5%', color:'black'}">
          {{län.lan}}
        </div>
      </div>
    </div>
    </div>
    <div :style="{ height:'300px', width:'100%'}">
      <div :style="{ height:'300px', width:'100%'}">
        <chart :style="{height:'300px',marginTop:'5%', marginLeft:'4%', width:'96%'}" :option="chartTopCategoryPerLan"></chart>
      </div>
    </div>

    <div :style="{ height:'30px',marginTop:'5%',fontWeight:'600', width:'100%'}">
      Välj  kategori
    </div>

    <div class="search_class">
      <input v-model="search_item" type="text" id="1" name="input" v-on:keyup.enter="onEnter"
        v-on:keyup="get_company_search(search_item)"
        :style="{color:'black',marginLeft:'0.1%',paddingLeft:'1%',border:'none',borderRadius:'0px 28px 28px 0px',  width:'88%',
                height:'100%',fontSize:'18px',marginTop:'0%'}"
        placeholder="Vad behöver du laga/fixa? " value="">
      <b-icon :style="{ width:'15px', marginRight:'1%',marginTop:'2%', cursor:'pointer'}"
      icon="x-lg"
      @click="open_search()"  v-if="this.search_item_flag==1"   />


    </div>
    <div v-if="this.search_item_flag==1 " :style="{height:'200px',width:'100%',overflowY:'auto'}" >

         <div v-for="(search,idx) in search_list" :key="idx"
         :style="{marginLeft:'1%',textAlign:'left',  width:'99%',height:'27px',  fontWeight:'bold', marginTop:'1%'}">
           <div :style="{width:'100%',height:'27px', display:'flex',marginTop:'1%'}"
            @click="to_category(search.id,search.category)">
             <!-- <img :src= "`${publicPath}`+ 'search.png'" :style="{height:'22px', marginTop:'2%',cursor:'pointer' , marginTop:'0%',marginLeft:'1.4%', width:'22px',borderRadius:'40%'}"/> -->
             <div :style="{marginLeft:'1%',marginTop:'1%',width:'100%',fontSize:'17px',lineHeight:'13px',fontWeight:500, height:'35px',color:'black'}">
               {{search.category}}
             </div>
           </div>
         </div>
    </div>
    <div :style="{ height:'300px', width:'100%'}">
      <div :style="{ height:'300px', width:'100%'}">
        <chart :style="{height:'300px',marginTop:'5%', marginLeft:'4%', width:'96%'}" :option="chartTopLanPerCategory"></chart>
      </div>
    </div>



  </div>
</template>

<script>
  import chart from "vue-echarts";
import router from '../router/index'
  import axios from 'axios';
import {API_URL} from '../../config.js'
export default {

  mounted(){
    this.get_analytics()
    this.län_list =[
      {'id':1, 'lan': 'Blekinge län'   },
      {'id':2, 'lan': 'Dalarnas län'   },
      {'id':3, 'lan': 'Gotlands län'   },
      {'id':4, 'lan': 'Gävleborgs län'   },
      {'id':5, 'lan': 'Hallands län'   },
      {'id':6, 'lan': 'Jämtlands län'   },
      {'id':7, 'lan': 'Jönköpings län'   },
      {'id':8, 'lan': 'Kalmar län'   },
      {'id':9, 'lan': 'Kronobergs län'   },
      {'id':10, 'lan': 'Norrbottens län'   },
      {'id':11, 'lan': 'Skåne län'   },
      {'id':12, 'lan':  'Stockholms län'  },
      {'id':13, 'lan':  'Södermanlands län'  },
      {'id':14, 'lan':  'Uppsala län'  },
      {'id':15, 'lan':  'Värmlands län'  },
      {'id':16, 'lan':  'Västerbottens län'  },
      {'id':17, 'lan':  'Västernorrlands län'  },
      {'id':18, 'lan':  'Västmanlands län'  },
      {'id':19, 'lan':  'Västra Götalands län'  },
      {'id':20, 'lan':  'Örebro län'  },
      {'id':21, 'lan':  'Östergötlands län'  }
    ]

  },
  components:{
    chart
  },
  methods:{
    open_lan(){
        if(this.län_opened){
          this.län_opened = false
        }
        else{
          this.län_opened = true
        }
    },
    choose_län_func(län_id,län_name){
      this.län_name =län_name
      this.lan_chosen = true
      this.län_opened=false
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/analyticsTopCategoryPerLan'+'?lan_id='+län_id,
        })
        .then(function(response){
          self.chartTopCategoryPerLan.xAxis.data =response.data["lantoCategory_lan"]
          self.chartTopCategoryPerLan.series[0].data =response.data["lantoCategory_count"]
        })
    },
    get_company_search(){
      this.search_item_flag =1
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/search_categories_analytics'+'?search_item='+this.search_item,
        })
        .then(function(response){
            self.search_list=response.data
        })
    },

    open_search(){

      this.search_item =''
      this.search_item_flag=0
    },
    to_category(category_id,category_name){
      this.search_item =category_name
      this.search_item_flag=0
      var self = this
     axios({
          method:'get',
          url: API_URL+ '/analyticsTopLansPerCategory'+'?category_id='+category_id,
        })
        .then(function(response){
         self.chartTopLanPerCategory.xAxis.data =response.data["lantoCategory_lan"]
         self.chartTopLanPerCategory.series[0].data =response.data["lantoCategory_count"]
        })
    },


    open_leads(){
        router.push({  path:"see_all_leads"})
    },
    open_google(){
      window.location.href="https://analytics.google.com/analytics/web/#/p347580124/reports/intelligenthome"
    },
    open_hotjar(){
      window.location.href="https://insights.hotjar.com/sites/3302510/dashboard"
    },
    get_analytics(){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/analytics',
        })
        .then(function(response){
          self.chartNewComp.series[0].data =response.data["new_companies_group_month"]
          self.chartTotalLoggedInComp.series[0].data =response.data["month_loggedin_companies_list"]
          self.chartTopLoggedInComp.xAxis.data =response.data["top_logged_in_companies_list_name"]
          self.chartTopLoggedInComp.series[0].data =response.data["top_logged_in_companies_list_val"]

          self.chartTopCompClick.xAxis.data =response.data["top_ten_click_company_list_name"]
          self.chartTopCompClick.series[0].data =response.data["top_ten_click_company_list_val"]

          self.chartwebsitevisits.xAxis.data =response.data["top_ten_click_web_company_list_name"]
          self.chartwebsitevisits.series[0].data =response.data["top_ten_click_web_company_list_val"]


          self.year = response.data["year"]
          self.chartCategoryClicksPerCat.xAxis.data =response.data["top_categ_group_list_cat"]
          self.chartCategoryClicksPerCat.series[0].data =response.data["top_categ_group_list_value"]

          self.chartClicksPerLan.xAxis.data =response.data["top_ten_click_lan_name"]
          self.chartClicksPerLan.series[0].data =response.data["top_ten_click_lan_val"]

          self.chartLanClicksPerMonth.xAxis.data =response.data["lan_click_per_month_month"]
          self.chartLanClicksPerMonth.series[0].data =response.data["lan_click_per_month_value"]

          self.chartLanClicksPerWeek.xAxis.data =response.data["lan_click_per_week_week"]
          self.chartLanClicksPerWeek.series[0].data =response.data["lan_click_per_week_value"]

          self.chartCategoryClicksPerMonth.xAxis.data =response.data["category_click_per_month_month"]
          self.chartCategoryClicksPerMonth.series[0].data =response.data["category_click_per_month_value"]

          self.chartCategoryClicksPerWeek.xAxis.data =response.data["category_click_per_week_week"]
          self.chartCategoryClicksPerWeek.series[0].data =response.data["category_click_per_week_value"]


    })
   }
  },
  data:function(){
      return{
        year:0,
        search_item_flag:0,
        lan_chosen:false,
        län_opened:false,
        search_item:'',
        lan_id:0,
        search_list:[],

        chartLanClicksPerMonth:{
          tooltip: {
          trigger: 'item',
          show: true,
          showContent: true,
          alwaysShowContent: true,
          triggerOn: 'mousemove',
          axisPointer:
        {
          label: {
            show: true,
          }
                }
          },
          xAxis: {data: []},
          yAxis: {  type: 'value'},
          series: [{type: 'bar',data:[]}],
          title: {text: "Top län click per month",x: "center",textStyle: {fontSize: 12}},
          color: ['#0A6338'],
        },
        chartLanClicksPerWeek:{
          tooltip: {
          trigger: 'item',
          show: true,
          showContent: true,
          alwaysShowContent: true,
          triggerOn: 'mousemove',
          axisPointer:
        {
          label: {
            show: true,
          }
                }
          },
          xAxis: {data: []},
          yAxis: {  type: 'value'},
          series: [{type: 'bar',data:[]}],
          title: {text: "Top län click per week",x: "center",textStyle: {fontSize: 12}},
          color: ['#0A6338'],
        },

        chartCategoryClicksPerWeek:{
          tooltip: {
          trigger: 'item',
          show: true,
          showContent: true,
          alwaysShowContent: true,
          triggerOn: 'mousemove',
          axisPointer:
        {
          label: {
            show: true,
          }
                }
          },
          xAxis: {data: []},
          yAxis: {  type: 'value'},
          series: [{type: 'bar',data:[]}],
          title: {text: "Top category click per week",x: "center",textStyle: {fontSize: 12}},
          color: ['#0A6338'],
        },
        chartCategoryClicksPerMonth:{
          tooltip: {
          trigger: 'item',
          show: true,
          showContent: true,
          alwaysShowContent: true,
          triggerOn: 'mousemove',
          axisPointer:
        {
          label: {
            show: true,
          }
                }
          },
          xAxis: {data: []},
          yAxis: {  type: 'value'},
          series: [{type: 'bar',data:[]}],
          title: {text: "Top category click per month",x: "center",textStyle: {fontSize: 12}},
          color: ['#0A6338'],
        },
        chartTopLanPerCategory:{
          tooltip: {
          trigger: 'item',
          show: true,
          showContent: true,
          alwaysShowContent: true,
          triggerOn: 'mousemove',
          axisPointer:
        {
          label: {
            show: true,
          }
                }
          },
          xAxis: {data: []},
          yAxis: {  type: 'value'},
          series: [{type: 'bar',data:[]}],
          title: {text: "Top län per category",x: "center",textStyle: {fontSize: 12}},
          color: ['#0A6338'],
        },
        chartTopCategoryPerLan:{
          tooltip: {
          trigger: 'item',
          show: true,
          showContent: true,
          alwaysShowContent: true,
          triggerOn: 'mousemove',
          axisPointer:
        {
          label: {
            show: true,
          }
                }
          },
          xAxis: {data: []},
          yAxis: {  type: 'value'},
          series: [{type: 'bar',data:[]}],
          title: {text: "Top categories per lan",x: "center",textStyle: {fontSize: 12}},
          color: ['#0A6338']
        },
        chartTotalComp:{
          tooltip: {
          trigger: 'item',
          show: true,
          showContent: true,
          alwaysShowContent: true,
          triggerOn: 'mousemove',
          axisPointer:
        {
          label: {
            show: true,
          }
                }
          },
          xAxis: {data: ['Jan', 'Feb', 'Mars', 'April','Maj','Juni','Juli','Aug','Sept','Nov', 'Dec']},
          yAxis: {  type: 'value'},
          series: [{type: 'line',data:[]}],
          title: {text: "Total Nr of companies",x: "center",textStyle: {fontSize: 12}},
          color: ['#0A6338'],
        },
        chartNewComp:{
          tooltip: {
          trigger: 'item',
          show: true,
          showContent: true,
          alwaysShowContent: true,
          triggerOn: 'mousemove',
          axisPointer:
        {
          label: {
            show: true,
          }
                }
          },
          xAxis: {data: ['Jan', 'Feb', 'Mars', 'April','Maj','Juni','Juli','Aug','Sept','Nov', 'Dec']},
          yAxis: {  type: 'value'},
          series: [{type: 'line',data:[]}],
          title: {text: "Newly added companies",x: "center",textStyle: {fontSize: 12}},
          color: ['#0A6338'],
        },
        chartTotalLoggedInComp:{
          tooltip: {
          trigger: 'item',
          show: true,
          showContent: true,
          alwaysShowContent: true,
          triggerOn: 'mousemove',
          axisPointer:
        {
          label: {
            show: true,
          }
                }
          },
          xAxis: {data: ['Jan', 'Feb', 'Mars', 'April','Maj','Juni','Juli','Aug','Sept','Nov', 'Dec']},
          yAxis: {  type: 'value'},
          series: [{type: 'line',data:[]}],
          title: {text: "Total logged ins of companies",x: "center",textStyle: {fontSize: 12}},
          color: ['#0A6338'],
        },
        chartTopLoggedInComp:{
          tooltip: {
            trigger: 'item',
            show: true,
            showContent: true,
            alwaysShowContent: true,
            triggerOn: 'mousemove',
            axisPointer:
                {
                  label: {
                    show: true,
                  }
                }
          },
          xAxis: {data: []},
          yAxis: {  type: 'value'},
          series: [{type: 'bar',data:[]}],
          title: {text: "Top logged in Companies total",x: "center",textStyle: {fontSize: 12}},
          color: ['#0A6338'],
        },
        chartwebsitevisits:{
          xAxis: {data: []},
          yAxis: {  type: 'value'},
          series: [{type: 'bar',data:[]}],
          tooltip: {
            trigger: 'item',
            show: true,
            showContent: true,
            alwaysShowContent: true,
            triggerOn: 'mousemove',
            axisPointer:
                {
                  label: {
                    show: true,
                  }
                }
          },
          title: {text: "Companies with top website clicks ",x: "center",textStyle: {fontSize: 12}},
          color: ['#0A6338'],
        },
        chartTopCompClick:{
          xAxis: {data: [] ,
                 axisLabel: {
                           interval:0,
                            rotate:40,
                           width:400,
                           overflow: 'break'
                        },
          },
          tooltip: {
            trigger: 'item',
            show: true,
            showContent: true,
            alwaysShowContent: true,
            triggerOn: 'mousemove',
            axisPointer:
                {
                  label: {
                    show: true,
                  }
                }
          },
          yAxis: {  type: 'value'},
          series: [{type: 'bar',data:[]}],
          title: {text: "Companies with top clicks",x: "center",textStyle: {fontSize: 12}},
          color: ['#0A6338'],
        },
            chartCategoryClicksPerCat:{
            tooltip: {
                trigger: 'item',
                show: true,
                showContent: true,
                alwaysShowContent: true,
                triggerOn: 'mousemove',
                axisPointer:
              {
                label: {
                  show: true,
                }
                      }
                },
                xAxis: {data: [],
                axisLabel: {
                   interval:0,
                    rotate:40,
                   width:400,
                   overflow: 'break'
                },

              },
              yAxis: {  type: 'value'},
              series: [{type: 'bar',data:[]}],
              title: {text: "Total Nr of Clicks for top 10 categories ",x: "center",textStyle: {fontSize: 12}},
              color: ['#0A6338'],
            },
            chartClicksPerLan:{
            tooltip: {
                trigger: 'item',
                show: true,
                showContent: true,
                alwaysShowContent: true,
                triggerOn: 'mousemove',
                axisPointer:
              {
                label: {
                  show: true,
                }
                      }
                },
                xAxis: {data: [],
                axisLabel: {
                   interval:0,
                    rotate:40,
                   width:400,
                   overflow: 'break'
                },

              },
              yAxis: {  type: 'value'},
              series: [{type: 'bar',data:[]}],
              title: {text: "Total Nr of Lan ",x: "center",textStyle: {fontSize: 12}},
              color: ['#0A6338'],
            },

        }
      }


}
</script>

<style lang="css" scoped>
.search_class{
  width: 60%;
  height: 80px;
  margin-left: 20%;
  margin-top: 4%;
  /* background: lightgrey; */
  /* rgba(196, 196, 196, 0.32); */
  border-radius: 28px;
  display: flex;
  border-style: solid;
}
</style>
